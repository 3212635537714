exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-jsx": () => import("./../../../src/pages/about-us.jsx" /* webpackChunkName: "component---src-pages-about-us-jsx" */),
  "component---src-pages-blog-details-jsx": () => import("./../../../src/pages/blog-details.jsx" /* webpackChunkName: "component---src-pages-blog-details-jsx" */),
  "component---src-pages-blog-jsx": () => import("./../../../src/pages/blog.jsx" /* webpackChunkName: "component---src-pages-blog-jsx" */),
  "component---src-pages-careers-jsx": () => import("./../../../src/pages/careers.jsx" /* webpackChunkName: "component---src-pages-careers-jsx" */),
  "component---src-pages-contact-us-jsx": () => import("./../../../src/pages/contact-us.jsx" /* webpackChunkName: "component---src-pages-contact-us-jsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-landing-demo-jsx": () => import("./../../../src/pages/landing-demo.jsx" /* webpackChunkName: "component---src-pages-landing-demo-jsx" */),
  "component---src-pages-people-[id]-jsx": () => import("./../../../src/pages/people/[id].jsx" /* webpackChunkName: "component---src-pages-people-[id]-jsx" */),
  "component---src-pages-pitch-to-us-jsx": () => import("./../../../src/pages/pitch-to-us.jsx" /* webpackChunkName: "component---src-pages-pitch-to-us-jsx" */),
  "component---src-pages-portfolio-jsx": () => import("./../../../src/pages/portfolio.jsx" /* webpackChunkName: "component---src-pages-portfolio-jsx" */),
  "component---src-pages-press-jsx": () => import("./../../../src/pages/press.jsx" /* webpackChunkName: "component---src-pages-press-jsx" */),
  "component---src-pages-test-jsx": () => import("./../../../src/pages/test.jsx" /* webpackChunkName: "component---src-pages-test-jsx" */),
  "component---src-templates-blog-template-js": () => import("./../../../src/templates/blog-template.js" /* webpackChunkName: "component---src-templates-blog-template-js" */),
  "component---src-templates-job-template-js": () => import("./../../../src/templates/job-template.js" /* webpackChunkName: "component---src-templates-job-template-js" */),
  "component---src-templates-legal-template-js": () => import("./../../../src/templates/legal-template.js" /* webpackChunkName: "component---src-templates-legal-template-js" */),
  "component---src-templates-person-details-template-js": () => import("./../../../src/templates/person-details-template.js" /* webpackChunkName: "component---src-templates-person-details-template-js" */),
  "component---src-templates-portfolio-details-template-js": () => import("./../../../src/templates/portfolio-details-template.js" /* webpackChunkName: "component---src-templates-portfolio-details-template-js" */)
}

